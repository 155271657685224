/** @format */
import React from 'react';

class Main extends React.Component {

	render() {
		return (
            <main property="mainContentOfPage" resource="#wb-main" typeof="WebPageElement">
                <link
                  rel="stylesheet"
                  href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
                  integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
                  crossOrigin="anonymous"
                />
                <div className="container">
                  <h1 id="wb-cont" property="name">GCWeb, the WET-BOEW Canada.ca theme</h1>
                  <div className="row">
                    <div className="col-md-7 col-lg-8">
                      <p>
                        The page templates and design patterns below comprise a reference
                        implementation of the
                        <a href="https://design.canada.ca">Canada.ca design system</a>,
                        including the mandatory requirement of the Content and Information
                        Architecture (C&amp;IA) Specification. Government of Canada
                        departments and agencies can contribute additional patterns and
                        templates via
                        <a href="https://github.com/wet-boew/GCWeb">GCWeb github repository</a
                        >.
                      </p>
                    </div>
                    <div className="col-xs-12 col-md-auto pull-right">
                      <p>
                        <a
                          href="https://github.com/wet-boew/GCWeb/archive/v10.2.0.zip"
                          className="btn btn-primary"
                          >Download GCWeb theme <strong>v10.2.0</strong></a
                        ><br />
                        <small
                          >(<time>2021-12-09</time> -
                          <a href="https://github.com/wet-boew/gcweb/releases/tag/v10.2.0"
                            >Release notes</a
                          >)</small
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <div className="container-fluid wb-inview show-none" data-inview="nav-menu">
                  <div className="row">
                    <nav className="well well-lg mrgn-tp-md">
                      <div className="container">
                        <h2 className="mrgn-tp-0">JSON Test (following content added or modified by json)</h2>
                            {/* data test */}

                            <p>My product ABC cost <a data-json-replacewith="./data/data-en.json#/fees/ABC" href="#">(consult our fees schedule)</a></p>

                            <p className="mrgn-tp-md">Your are currently viewing the product &quot;<span data-json-replace="./data/data-en.json#/product">Unknown</span>&quot;</p>

                            <p className="mrgn-tp-md" data-wb-json='
                              [
                                {
                                  "url": "./data/data-en.json#/product",
                                  "type": "replace"
                                },
                                {
                                  "url": "./data/data-en.json#/status",
                                  "type": "addclass"
                                }
                              ]'>This is a paragraph.
                              </p>


                            {/* end of data test */}
                      </div>
                    </nav>
                  </div>
                </div>

                <div className="container-fluid wb-inview show-none" data-inview="nav-menu">
                  <div className="row">
                    <nav className="well well-lg mrgn-tp-md">
                      <div className="container">
                        <h2 className="mrgn-tp-0">Get started</h2>
                        <ul className="list-unstyled colcount-md-3">
                          <li>
                            <a href="#components"
                              ><span
                                aria-hidden="true"
                                className="fas fa-cube mrgn-rght-md"
                              ></span
                              >Components</a
                            >
                          </li>
                          <li>
                            <a href="#templates"
                              ><span
                                aria-hidden="true"
                                className="fas fa-table mrgn-rght-md"
                              ></span
                              >Templates</a
                            >
                          </li>
                          <li>
                            <a href="#experiment"
                              ><span
                                aria-hidden="true"
                                className="fas fa-puzzle-piece mrgn-rght-md"
                              ></span
                              >Méli-mélo and thematics</a
                            >
                          </li>
                          <li>
                            <a href="#sitesglobal"
                              ><span
                                aria-hidden="true"
                                className="fas fa-globe mrgn-rght-md"
                              ></span
                              >Sites and global functionality</a
                            >
                          </li>
                          <li>
                            <a href="#other"
                              ><span
                                aria-hidden="true"
                                className="fas fa-info-circle mrgn-rght-md"
                              ></span
                              >Other documentation</a
                            >
                          </li>
                          <li>
                            <a href="#developing-for-gcweb"
                              ><span
                                aria-hidden="true"
                                className="fas fa-code mrgn-rght-md"
                              ></span
                              >Developing for GCWeb</a
                            >
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                <nav
                  id="nav-menu"
                  className="wb-overlay modal-content overlay-def wb-bar-t hidden-xs"
                  aria-hidden="true"
                >
                  <header className="pull-left">
                    <h2 className="modal-title">Get started</h2>
                  </header>
                  <ul className="pull-left list-inline mrgn-lft-md mrgn-tp-sm">
                    <li>
                      <a href="#components" className="btn btn-link text-white"
                        ><span aria-hidden="true" className="fas fa-cube mrgn-rght-md"></span
                        >Components</a
                      >
                    </li>
                    <li className="mrgn-lft-md">
                      <a href="#templates" className="btn btn-link text-white"
                        ><span aria-hidden="true" className="fas fa-table mrgn-rght-md"></span
                        >Templates</a
                      >
                    </li>
                    <li className="mrgn-lft-md">
                      <a href="#experiment" className="btn btn-link text-white"
                        ><span
                          aria-hidden="true"
                          className="fas fa-puzzle-piece mrgn-rght-md"
                        ></span
                        >Méli-mélo and thematics</a
                      >
                    </li>
                    <li className="mrgn-lft-md">
                      <a href="#sitesglobal" className="btn btn-link text-white"
                        ><span aria-hidden="true" className="fas fa-globe mrgn-rght-md"></span
                        >Sites and global</a
                      >
                    </li>
                    <li className="mrgn-lft-md">
                      <a href="#other" className="btn btn-link text-white"
                        ><span
                          aria-hidden="true"
                          className="fas fa-info-circle mrgn-rght-md"
                        ></span
                        >Other</a
                      >
                    </li>
                    <li className="mrgn-lft-md">
                      <a href="#developing-for-gcweb" className="btn btn-link text-white"
                        ><span aria-hidden="true" className="fas fa-code mrgn-rght-md"></span
                        >Developing for GCWeb</a
                      >
                    </li>
                  </ul>
                </nav>
                <div className="container">
                  <p>
                    <small
                      >Found an C&amp;IA implementation issue or you want to contribute at
                      their development, let us know by submiting
                      <a
                        href="https://github.com/wet-boew/GCWeb/issues/new?title=C&amp;IA%20implementation%20error:%20"
                        >GCweb issue</a
                      >, sending
                      <a href="https://github.com/wet-boew/GCWeb/pulls">pull request</a> or by
                      participating at one of our
                      <a
                        href="https://wet-boew.github.io/wet-boew-documentation/index-en.html#wet-boew-code-sprint"
                        >WET-BOEW weekly Tuesday code sprint</a
                      >.</small
                    >
                  </p>

                  <details className="mrgn-tp-lg">
                    <summary>Meaning of statuses</summary>
                    <dl className="dl-horizontal mrgn-bttm-0">
                      <dt><span className="label label-success">Stable</span></dt>
                      <dd>Meet the latest published specification.</dd>
                      <dt><span className="label label-warning">Provisional</span></dt>
                      <dd>Relatively stable, yet experimental; use as your own risks.</dd>
                      <dt><span className="label label-danger">Deprecated</span></dt>
                      <dd>
                        Do not use because it's deprecated, but listed here for your
                        information.
                      </dd>
                      <dt><span className="label label-default">Undefined</span></dt>
                      <dd>Missing State in the component documentation.</dd>
                      <dt><span className="label label-success">Up to spec</span></dt>
                    <dd>Meet the latest published C&amp;IA specification.</dd>
                    <dt><span className="label label-info">Informational</span></dt>
                    <dd>It's for your information. It's complete and suggestive but not defined by and from a specification yet.</dd>
                    <dt><span className="label label-info">Need to revalidate</span></dt>
                    <dd>Was meeting the preceding published specification, but it need to be manually revalidated to ensure it continues to meet the latest published specification.</dd>
                    <dt><span className="label label-warning">Partial</span></dt>
                    <dd>Partially up to spec or partially stable in order to meet other core web standards such WCAG 2.0 Level AA.</dd>
                    <dt><span className="label label-warning">Outdated</span></dt>
                    <dd>Don't meet the latest specification but met a previous version. It requires updates.</dd>
                    <dt><span className="label label-default">Backlog</span></dt>
                    <dd>Need to be developped.</dd>
                    <dt><span className="label label-danger">Incomplete</span></dt>
                    <dd>Incomplete because it don't fully meet all the specification yet. Still need developpement work.</dd>
                    </dl>
                  </details>
                  <h2 id="components" className="mrgn-bttm-lg">Components</h2>
                  <ul
                    className="row list-unstyled wb-eqht wb-filter mrgn-tp-md"
                    data-wb-filter='{ "selector": ">li" }'
                  >
                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Checkboxes and radio buttons

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>
                            Checkboxes and radio buttons designed based on user experience
                            testing
                          </p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="components/gc-chckbxrdio/gc-chckbxrdio-doc-en.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/gc-chckbxrdio/gc-chckbxrdio-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Checkboxes and radio buttons</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Featured link - Documentation and working example

                          <span className="label label-warning mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Provisional</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>
                            This adds a featured link to a page that your institution needs to
                            feature prominently.
                          </p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/gc-featured-link/gc-featured-link-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Featured link - Documentation and working example</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Context-specific features

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Context-specific features</p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="components/gc-features/gc-features-en.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Feeds plugin GC override

                          <span className="label label-warning mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Provisional</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Overrides the WET ATOM feeds to apply GC design pattern.</p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="components/gc-feeds/gc-feeds-en.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Most requested - Documentation and working example

                          <span className="label label-warning mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Provisional</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Features top tasks related to the page it is on.</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/gc-most-requested/gc-most-requested-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Most requested - Documentation and working example</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Services and information

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Services and information</p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="components/gc-servinfo/gc-srvinfo.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/gc-servinfo/gc-srvinfo-examples.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Services and information</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Subway map menu

                          <span className="label label-warning mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Provisional</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>GC Subway map menu</p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="components/gc-subway/docs-en.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/gc-subway/gc-subway-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Subway map menu</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/gc-subway/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >GC Subway Index page</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/gc-subway/page2-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Page 2] - GC Subway map menu</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          GC tables

                          <span className="label label-warning mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Provisional</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Simple responsive gc tables</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/gc-table/gc-table-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Tables</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Well header responsive

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Context-specific features</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/header-rwd/well-header-rwd-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Well header responsive</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Other components

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Components included but need to move in its own folder.</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/provisional-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Provisional functionality</a
                                >
                              </li>

                              <li>
                                <a href="components/redacted-en.html" lang="en" hrefLang="en"
                                  >Redacted text</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/components-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Download link, In-page table of contents, Alerts designs</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/deprecated-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Deprecated markup - Report a problem (v4.0.28), gc-nttvs,
                                  gc-prtts</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/tbl-floatcolumn-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Float column in a data table</a
                                >
                              </li>

                              <li>
                                <a href="components/video-en.html" lang="en" hrefLang="en"
                                  >Video example</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Context-specific features

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Context-specific features</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a href="components/list/list-en.html" lang="en" hrefLang="en"
                                  >List - Additional style</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Chat wizard

                          <span className="label label-warning mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Provisional</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>
                            Transforms a simple form into a conversation like experience used
                            as a wizard.
                          </p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="components/wb-chtwzrd/docs.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/wb-chtwzrd/chatwizard-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Chat wizard</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/wb-chtwzrd/chatwizard-json-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >with JSON setting</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Data JSON

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Insert content extracted from a JSON file.</p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="components/wb-data-json/data-json-doc-en.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/wb-data-json/data-json-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Data JSON</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/wb-data-json/template-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Template HTML 5</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Do action

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>
                            Execute action, like filter a table, based on pre-established set
                            of configuration.
                          </p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="components/wb-doaction/doaction-doc-en.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/wb-doaction/tblfilter-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Table</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/wb-doaction/json-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >JSON</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Fieldflow

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>[Short description of the component]</p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="components/wb-fieldflow/fieldflow-doc-en.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/wb-fieldflow/fieldflow-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Fieldflow</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/wb-fieldflow/basic-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Basic</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/wb-fieldflow/advanced-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Advanced</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/wb-fieldflow/alternative-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Alternatives</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          JSON Manager

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Manage dataset and apply JSON Patch</p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="components/wb-jsonmanager/jsonmanager-doc-en.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/wb-jsonmanager/jsonmanager-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >JSON Manager</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/wb-jsonmanager/jsonmanager-doaction-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >with Do Action patches</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Datalist dynamic suggestion

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Load datalist suggestion from a JSON file</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/wb-suggest/suggest-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Datalist dynamic suggestion</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          URL Mapping

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Execute pre-configured ajax action based on url query string.</p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="components/wb-urlmapping/urlmapping-doc-en.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/wb-urlmapping/ajax-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Ajax</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/wb-urlmapping/tblfilter-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Table filtering</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/wb-urlmapping/patches-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Patching JSON</a
                                >
                              </li>

                              <li>
                                <a
                                  href="components/wb-urlmapping/geomap-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Geomap</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          wb5-click postback converter

                          <span className="label label-danger mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Deprecated</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>
                            Convert deprecated wb5-click postback form syntax to wb-postback
                            plugin
                          </p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/wb5-click-postback/wb5-click-postback-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >wb5-click postback converter</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Well bold

                          <span className="label label-warning mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Provisional</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>
                            This apply font weight bold to evey text element inside a well
                            component and generates normal font weight when the element
                            <code>strong</code> is applied.
                          </p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="components/well-bold/well-bold-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Well bold - Documentation and working example</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <hr />
                  <h2 id="templates" className="mrgn-bttm-lg">Templates</h2>
                  <ul
                    className="row list-unstyled wb-eqht wb-filter mrgn-tp-md"
                    data-wb-filter='{ "selector": ">li" }'
                  >
                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Advanced Service - Probably deprecated

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Probably deprecated - template for advanced service</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="templates/advancedservice/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Service name] - 1. [Step / section page name]</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/advancedservice/page2-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Service name] - 2. [Step / section page name]</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/advancedservice/page3-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Service name] - 3. [Step / section page name]</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/advancedservice/page4-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Service name] - 4. [Step / section page name]</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/advancedservice/page5-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Service name] - 5. [Step / section page name]</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/advancedservice/page6-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Service name] - 6. [Step / section page name]</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Campaign name

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Campaign name templates samples</p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="templates/campaign/api.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="templates/campaign/campaign-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Campaign name</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/campaign/campaign2-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Campaign name 2</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Home

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Home templates</p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="templates/home/api.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a href="templates/home/home-en.html" lang="en" hrefLang="en"
                                  >Home - Canada.ca</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Other template

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Template included but need to move in its own folder.</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a href="templates/content-en.html" lang="en" hrefLang="en"
                                  >Content page</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/content-fluid-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Fluid content page</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/content-limit-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Content page - Limited width content</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/content-gcweb-4-0-29-font-style-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Content page - GCWeb 4.0.29 font stylee</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/content-signedoff-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Content page - Signed Off</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/content-signedon-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Content page - Signed On</a
                                >
                              </li>

                              <li>
                                <a href="templates/widgets-en.html" lang="en" hrefLang="en"
                                  >Stay connected</a
                                >
                              </li>

                              <li>
                                <a href="templates/theme-en.html" lang="en" hrefLang="en"
                                  >[Theme title]</a
                                >
                              </li>

                              <li>
                                <a href="templates/dept-en.html" lang="en" hrefLang="en"
                                  >Departments and agencies</a
                                >
                              </li>

                              <li>
                                <a href="templates/event-en.html" lang="en" hrefLang="en"
                                  >Promotional events page (Campaign?)</a
                                >
                              </li>

                              <li>
                                <a href="templates/feedback-en.html" lang="en" hrefLang="en"
                                  >Feedback form</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/gc-audience-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Audience name]</a
                                >
                              </li>

                              <li>
                                <a href="templates/service-en.html" lang="en" hrefLang="en"
                                  >Service initiation - Might deprecated</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Index

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Index templates</p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="templates/index/api.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="templates/index/longindex-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Long index page]</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Institutional profile

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Institutional profile templates</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="templates/institutional/institution-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Institution Name]</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/institutional/institution-landing-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Institution Name] - landing page (provisional)</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/institutional/institution-contact-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Contact [Institution name]</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/institutional/institutional-service-performance-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Institution] service performance reporting for fiscal year
                                  2015 to 2016</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/institutional/institution-arms-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >arm’s length - [Institution Name]</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Laws and regulations pages

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>
                            The laws and regulations collection provides access to Government
                            of Canada acts and regulations through their individual profiles.
                          </p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="templates/legislation/act-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Act name] <small>([NN-XX])</small></a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/legislation/regulations-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Regulation name] <small>([NN-XX])</small></a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Local navigation

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Template sample with a local navigation</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="templates/localnav/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Topic - Local navigation]</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task1/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Task 1</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task1/task1/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Sub task 1</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task1/task2/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Sub task 2</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task1/task3/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Sub task 3</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task1/task4/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Sub task 4</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task1/task5/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Sub task 5</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task1/task6/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Sub task 6</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task1/task7/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Sub task 7</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task2/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Task 2</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task3/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Task 3</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task3/task1/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Sub task 1</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task3/task2/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Sub task 2</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task3/task2/task1/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Sub sub task 1</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task3/task2/task2/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Sub sub task 2</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task3/task3/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Sub task 3</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/localnav/task4/index-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Task 4</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Ministerial profile

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Ministerial profile templates documentation and example</p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="templates/ministerial/ministerial-doc-en.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="templates/ministerial/ministerial-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Ministerial profile</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/ministerial/ministerial-reduced-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Ministerial profile - swearing-in day</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/ministerial/parliamentary-secretaries-reduced-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Parliamentary secretaries profile - swearing-in day</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          News

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>News templates samples</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a href="templates/news/news-en.html" lang="en" hrefLang="en"
                                  >News</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Organizational profile

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Organizational profile templates</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="templates/organizational/organizational-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Organization Name]</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/organizational/organizational-arms-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >arm’s length - [Organization Name]</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Search results

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Search templates</p>

                          <ul className="list-unstyled mrgn-bttm-lg mrgn-lft-md">
                            <li>
                              <a href="templates/search/api.html"
                                ><span
                                  className="fas fa-info-circle small mrgn-rght-sm"
                                  aria-hidden="true"
                                ></span
                                >Documentation</a
                              >
                            </li>
                          </ul>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="templates/search/results-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Search results</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/search/results-filters-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Search facets/filters results</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/search/results-contextual-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Contextual</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Server error message

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Error server page</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="templates/servermessage/404-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >We couldn't find that Web page (Error 404) - Canada.ca
                                  theme</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/servermessage/404-en-fr.html"
                                  lang="en"
                                  hrefLang="en"
                                  >We couldn't find that Web page (Error 404) - Canada.ca
                                  theme / Nous ne pouvons trouver cette page Web (Erreur 404)
                                  - Thème Canada.ca</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/servermessage/servermessage-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Message title - Canada.ca theme</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/servermessage/servermessage-en-fr.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Message title - Canada.ca theme / Titre du message - Thème
                                  Canada.ca</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Splash page - Canada.ca

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Splash page example</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="templates/splash/splashpage.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Splash page example</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Thematic

                          <span className="label label-warning mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Provisional</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Site variant where a thematic is applied</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="templates/thematic/pink-day-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Pink Day</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/thematic/pink-day-home-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Pink Day - Home</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/thematic/dark-theme-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Dark theme</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/thematic/dark-theme-home-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >Dark theme - Home</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Beta - Theme, Topic

                          <span className="label label-warning mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Provisional</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Theme, Topic beta templates</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="templates/theme-topic/theme-topic-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Theme - Topic title]</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>

                    <li className="col-md-6 mrgn-tp-md mrgn-bttm-md">
                      <div className="brdr-tp brdr-rght brdr-bttm brdr-lft hght-inhrt">
                        <h3 className="mrgn-tp-md mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          Topic

                          <span className="label label-success mrgn-lft-sm"
                            ><span className="wb-inv">State: </span>Stable</span
                          >
                        </h3>
                        <div className="mrgn-rght-md mrgn-bttm-md mrgn-lft-md">
                          <p>Topic template examples</p>

                          <details className="mrgn-tp-lg">
                            <summary>Examples</summary>
                            <ul className="mrgn-tp-md">
                              <li>
                                <a
                                  href="templates/topic/topic-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Topic title]</a
                                >
                              </li>

                              <li>
                                <a
                                  href="templates/topic/topic-testcase-1-en.html"
                                  lang="en"
                                  hrefLang="en"
                                  >[Topic title, test case 1]</a
                                >
                              </li>
                            </ul>
                          </details>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="well well-lg mrgn-tp-md bg-gctheme text-white">
                      <div className="container mrgn-bttm-lg">
                        <h2 id="experiment" className="mrgn-tp-md mrgn-bttm-0">
                          Special features
                        </h2>
                        <div className="row">
                          <div className="col-md-6">
                            <h3 id="méli-mélo">Méli-mélo</h3>
                            <p>
                              The experimentation hub you have been waiting for! Méli-mélos
                              allow you to bring in some awesome new code you would usually
                              consider "custom" to the Canada.ca theme.
                            </p>
                            <p className="mrgn-tp-lg">
                              <a
                                href="méli-mélo/méli-mélo-en.html"
                                className="btn btn-default btn-lg"
                                >Learn more<span className="wb-inv"> about méli-mélo</span></a
                              >
                            </p>
                          </div>
                          <div className="col-md-6">
                            <h3 id="thematics">Promotional thematics</h3>
                            <p>
                              Do you have a set of pages that use an original look related to
                              a specific promotion? Try this special hub for promotional
                              thematics instead.
                            </p>
                            <p className="mrgn-tp-lg">
                              <a
                                href="th%C3%A9matique/gc-th%C3%A9matique-en.html"
                                className="btn btn-default btn-lg"
                                >Learn more<span className="wb-inv">
                                  about promotional thematics</span
                                ></a
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <h2 id="sitesglobal">Sites and global functionality</h2>
                  <ul>
                    <li>
                      Site wide demoed feature (État: Stable)
                      <ul>
                        <li>
                          Examples
                          <ul>
                            <li>
                              <a
                                href="sites/archived/archived-en.html"
                                lang="en"
                                hrefLang="en"
                                >Archived - Content page</a
                              >
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li>
                      Breadcrumbs (État: Stable)
                      <ul>
                        <li>
                          Documentations
                          <ul>
                            <li>
                              <a
                                href="sites/breadcrumbs/breadcrumbs-en.html"
                                lang="en"
                                hrefLang="en"
                                >Breadcrumbs</a
                              >
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li>
                      Helpers (État: Stable)
                      <ul>
                        <li>
                          Documentations
                          <ul>
                            <li>
                              <a href="sites/helpers/colour-en.html" lang="en" hrefLang="en"
                                >Colour</a
                              >
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <h2 id="other">Other documentation</h2>
                  <h3>WET-BOEW feature demos styled with Canada.ca theme</h3>
                  <p>
                    <a href="/gcweb-compiled-demos/index.html#wet-boew"
                      >WET-BOEW feature overview</a
                    >
                  </p>
                  <h3 id="gcweb-project-documentation">GCWeb project documentation</h3>
                  <ul>
                    <li><a href="docs/index.html">GCWeb theme - Meta information</a></li>
                    <li>
                      <a href="docs/implementing.html"
                        >Quick implementation guide - GCWeb theme</a
                      >
                    </li>
                    <li>
                      <a href="docs/v5-migration.html"
                        >Migration instruction - GCWeb theme V5</a
                      >
                    </li>
                    <li>
                      <a href="docs/GCWeb-en.html"
                        >Archived - Documentation - GCWeb English</a
                      >
                    </li>
                    <li>
                      <a href="docs/release/index-en.html">Archived - Releases English</a>
                    </li>
                    <li>
                      <a href="docs/static-header-footer/bootstrap-3.html"
                        >Skeleton - Static header/footer - Bootstrap 3</a
                      >
                    </li>
                    <li>
                      <a href="docs/static-header-footer/bootstrap-4.html"
                        >Prototype skeleton - Static header/footer - Bootstrap 4</a
                      >
                    </li>
                  </ul>
                  <h4>Evaluations and reports</h4>
                  <ul>
                    <li>
                      <a href="docs/evaluation-report/1-accessibility.html"
                        >1 - Accessibility assessment as 2018-11-14</a
                      >
                    </li>
                    <li>
                      <a href="docs/evaluation-report/2-wetplugin-gcweb2.html"
                        >2 - Regression user acceptance testing as 2018-11-16</a
                      >
                    </li>
                  </ul>
                  <div className="row">
                    <div className="col-md-9">
                      <h2 id="developing-for-gcweb">
                        <span aria-hidden="true" className="fas fa-code mrgn-rght-md"></span
                        >Developping for GCWeb
                      </h2>
                      <p>
                        Join the community and start creating, improving and fixing GCWeb, the
                        Canada.ca theme. Learn how to set up your local environment.
                      </p>
                      <p className="mrgn-bttm-lg">
                        <a href="docs/developing-en.html" className="btn btn-call-to-action"
                          >Start coding</a
                        >
                      </p>
                    </div>
                  </div>
                </div>

                <div className="pagedetails container">
                  <div className="row">
                    <div className="col-sm-6 col-md-5 col-lg-4">
                      <details className="brdr-0">
                        <summary className="btn btn-default text-center">
                          Report a problem on this page
                        </summary>
                        <div className="well row">
                          <div className="gc-rprt-prblm">
                            <div className="gc-rprt-prblm-frm gc-rprt-prblm-tggl">
                              <form action="#">
                                <fieldset>
                                  <legend>
                                    <span className="field-name"
                                      >Please select all that apply:</span
                                    >
                                  </legend>
                                  <div className="checkbox">
                                    <label htmlFor="gc-rprt-prblm-f1">
                                      <input
                                        type="checkbox"
                                        name="problem1"
                                        value="Yes"
                                        id="gc-rprt-prblm-f1"
                                      />A link, button or video is not working
                                    </label>
                                    <input name="problem1" type="hidden" value="No" />
                                  </div>
                                  <div className="checkbox">
                                    <label htmlFor="gc-rprt-prblm-f2">
                                      <input
                                        type="checkbox"
                                        name="problem2"
                                        value="Yes"
                                        id="gc-rprt-prblm-f2"
                                      />It has a spelling mistake
                                    </label>
                                    <input name="problem2" type="hidden" value="No" />
                                  </div>
                                  <div className="checkbox">
                                    <label htmlFor="gc-rprt-prblm-f3">
                                      <input
                                        type="checkbox"
                                        name="problem3"
                                        value="Yes"
                                        id="gc-rprt-prblm-f3"
                                      />Information is missing
                                    </label>
                                    <input name="problem3" type="hidden" value="No" />
                                  </div>
                                  <div className="checkbox">
                                    <label htmlFor="gc-rprt-prblm-f4">
                                      <input
                                        type="checkbox"
                                        name="problem4"
                                        value="Yes"
                                        id="gc-rprt-prblm-f4"
                                      />Information is outdated or wrong
                                    </label>
                                    <input name="problem4" type="hidden" value="No" />
                                  </div>
                                  <div className="checkbox">
                                    <label htmlFor="gc-rprt-prblm-f5">
                                      <input
                                        type="checkbox"
                                        name="problem5"
                                        value="Yes"
                                        id="gc-rprt-prblm-f5"
                                      />I can't find what I'm looking for
                                    </label>
                                    <input name="problem5" type="hidden" value="No" />
                                  </div>
                                  <div className="checkbox">
                                    <label htmlFor="gc-rprt-prblm-f6">
                                      <input
                                        type="checkbox"
                                        name="problem6"
                                        value="Yes"
                                        id="gc-rprt-prblm-f6"
                                      />Other issue not in this list
                                    </label>
                                    <input name="problem6" type="hidden" value="No" />
                                  </div>
                                </fieldset>
                                <button
                                  type="submit"
                                  className="btn btn-primary wb-toggle"
                                  data-toggle='{"stateOff": "hide", "stateOn": "show", "selector": ".gc-rprt-prblm-tggl"}'
                                >
                                  Submit
                                </button>
                              </form>
                            </div>
                            <div className="gc-rprt-prblm-thnk gc-rprt-prblm-tggl hide">
                              <h3>Thank you for your help!</h3>
                              <p>
                                You will not receive a reply. For enquiries, please
                                <a href="https://www.canada.ca/en/contact.html">contact us</a
                                >.
                              </p>
                            </div>
                          </div>
                        </div>
                      </details>
                    </div>
                  </div>
                  <dl id="wb-dtmd">
                    <dt>Date modified:</dt>
                    <dd><time property="dateModified">2021-12-09</time></dd>
                  </dl>
                </div>
            </main>
            );
	  }
}

export default Main;