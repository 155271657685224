/** @format */

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Home';

export default class MainRouter extends React.Component {
	render () {
		return (
			<BrowserRouter>
				<React.Fragment>
					<Switch>
						<Route exact path='/' component={Home} />
					</Switch>
				</React.Fragment>
			</BrowserRouter>
		);
	}
}
